import React, { useState } from "react";
import { RiCloseFill, RiMenu3Line } from "react-icons/ri";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";

import { Menu } from "../../components/export";
// import { MdLocationOn, MdCall, MdMarkEmailUnread } from 'react-icons/md';
import Coeus_Logo from "../../Assets/CoeusLogo.png";

import "./Nav.css";

const Nav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <section className="nav-section nav">
      {/* <div className='nav-geo'>
                <p><MdLocationOn/>Pan India</p>
                <p><a href='tel:+919534433786'><MdCall />+91 953-4433-786</a></p>
                <p><a href='mailto:coeus@gmail.com'><MdMarkEmailUnread />coeus@gmail.com</a></p>
            </div> */}
      <div className={` navbar gradient_bg `}>
        <div className="navbar-links">
          <div className="navbar-links_logo">
            <a href="/">
              <img
                loading="eager"
                src={Coeus_Logo}
                alt="Coeus logo, Coeus Multi Services"
              />
            </a>
          </div>
          <div className="navbar-links_container">
            <Menu />
          </div>
          <footer>
            <div className="nav-socials">
              <p>
                <a
                  href="https://www.facebook.com/Coeus.Inc.Services"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare />
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/coeus.inc.services"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagramSquare />
                </a>
              </p>
              <p>
                <a
                  href="https://twitter.com/Coeus_Inc_"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitterSquare />
                </a>
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/company/couesmulti-services"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </p>
            </div>
          </footer>
        </div>
        <div className="navbar-menu">
          {toggleMenu ? (
            <RiCloseFill color="black" onClick={() => setToggleMenu(false)} />
          ) : (
            <RiMenu3Line color="black" onClick={() => setToggleMenu(true)} />
          )}
          {toggleMenu && (
            <div className="navbar-menu_container scale-up-center">
              <div
                className="navbar-menu_container-links"
                onClick={() => setToggleMenu(false)}
              >
                <Menu />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Nav;
