import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./Menu.css";

const Menu = () => {
  return (
    <div className="menu-items">
      <NavLink to="/">
        <li>Home</li>
      </NavLink>
      <NavLink to="/About">
        <li>About</li>
      </NavLink>

      <NavLink to="/Services">
        <li>
          Services
          <div class="dropdown-contents">
            <div className="dropdown-items">
              <p>
                <h3>
                  <Link to="/Services/Human-Resource">
                    Human Resource Services
                  </Link>
                </h3>

                <label>
                  <Link to="/Services/Human-Resource">
                    <li>Staffing</li>
                  </Link>
                </label>
                <label>
                  <Link to="/Services/Human-Resource">
                    <li>Hiring & RPO</li>
                  </Link>
                </label>
                <label>
                  <Link to="/Services/Human-Resource">
                    <li>Infrastructure & Asset</li>
                  </Link>
                </label>
                <label>
                  <Link to="/Services/Human-Resource">
                    <li>Od and L&D</li>
                  </Link>
                </label>
              </p>
            </div>

            <div className="dropdown-items">
              <p>
                <Link to="/Services/Investigation">
                  <h3>Investigation, Fraud & Governane</h3>
                </Link>

                <Link to="/Services/Investigation#/compliance-ethics">
                  <li>Compliance & Ethics</li>
                </Link>
                <Link to="/Services/Investigation#/corporate">
                  <li>Corporate</li>
                </Link>
                <Link to="/Services/Investigation#/litigation">
                  <li>Litigation Support</li>
                </Link>
              </p>
            </div>

            <div className="dropdown-items">
              <p>
                <h3>
                  <Link to="/Services/Labour-Law-Compliance">
                    Labour Law Compliance and litigations
                  </Link>
                </h3>

                <Link to="/Services/Labour-Law-Compliance#/Compliance-Governance">
                  <li>Compliance & Governance</li>
                </Link>
                <Link to="/Services/Labour-Law-Compliance#/Establishment-Compliance">
                  <li>Establishment Compliance</li>
                </Link>
                <Link to="/Services/Labour-Law-Compliance#/Vendor-Compliance">
                  <li>Vendor Compliance Management</li>
                </Link>
              </p>
            </div>
          </div>
        </li>
      </NavLink>

      {/* <NavLink to='/Contact'><li>Technologies
                <div class="dropdown-contents">

                    <div className='dropdown-items'>
                        <p>
                            <h3><Link to='/Services/Human-Resource'>Technologies</Link></h3>
                            <ul>
                                <li><Link to='/Services/Human-Resource' id='hr'>Link 1</Link></li>
                                <li><Link to='/Services/Human-Resource'>Link 1</Link></li>
                                <li><Link to='/Services/Human-Resource'>Link 1</Link></li>
                                <li><Link to='/Services/Human-Resource'>Link 1</Link></li>
                            </ul>
                        </p>
                    </div>

                </div>
            </li>
            </NavLink> */}

      {/* <a href='#Why_us'><li>Why Choose us</li></a> */}
      <NavLink to="/Contact">
        <li>Enquiry</li>
      </NavLink>
      <NavLink to="/Contact">
        <li>Contact Us</li>
      </NavLink>
      <a
        href="https://forms.gle/U2UBiZuhqNJ899af7"
        target="_blank"
        rel="noreferrer"
      >
        <li>Careers</li>
      </a>
      {/* <NavLink ><li>Associated Partner</li></NavLink>
            <NavLink ><li>Media</li></NavLink>
            <NavLink ><li>Partner with us</li></NavLink> */}
    </div>
  );
};

export default Menu;
