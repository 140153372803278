import React from 'react';
import { Link } from 'react-router-dom';
import './Illustrator.css';
import { FaPodcast, FaVolleyballBall } from 'react-icons/fa'
import { MdAdsClick, MdAdminPanelSettings, MdPersonSearch } from 'react-icons/md'

const Illustrator = () => {
  return (
    <section className='illustrator-section'>
      <div className='illustrator' id='Why_us'>
        <div className='illustrator-header'>
          <h2 className='sub-title'>Why Choose Us?</h2>

          {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur voluptatum nulla quam vitae reprehenderit eos cupiditate? Ipsam nisi nam iure, odit doloribus numquam reiciendis voluptas impedit? Vitae tempora labore est.</p> */}
        </div>

        <div className='illustrator-icons'>
          <article>
            <MdAdsClick />
            <p>Specialized knowledge based</p>
          </article>

          <article>
            <MdAdminPanelSettings />
            <p>Highly domain experienced team</p>
          </article>

          <article>
          <FaPodcast />
            <p>Dedicated account managers</p>
          </article>

          <article>
          <MdPersonSearch />
            <p>Tailor made solutions for HR
              services, investigation &
              Compliances.</p>
          </article>

          <article>
            <FaVolleyballBall />
            <p>Industry network, PAN India
              presence</p>
          </article>

        </div>
        <Link to='/Services'><button className='btn'>Know more about our services</button></Link>
      </div>


    </section>
  )
}

export default Illustrator