import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero">
        <h1 className="title">
          Coe<span className="span_U">u</span>s
        </h1>
        <h2>Intelligent HR Solutions at One Place</h2>
        {/* <h3>this is typing</h3> */}
        {/* <h3>A New Edge to Human Resources, Compliance, Investigation, Fraud & Governanace</h3> */}
        {/* <p>Customer Centricity, Innovative and agile <span>Coeus Inc Services Pvt. Ltd.</span> is a tech-enabled, seamless and evolving new edge organizations that specializes in <b>Talent Acquisition, Compliance, Fraud & Investigation </b>along with host of all other Human Resource services </p> */}
      </div>
    </section>
  );
};

export default Hero;
