import React from "react";
import { Link } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { RiGovernmentFill } from "react-icons/ri";
import { GoLaw } from "react-icons/go";

import "./Our_Services.css";

const Our_Services = () => {
  return (
    <section className="services-section">
      <div className="services">
        <h2 className="sub-title">Our Services</h2>

        <div className="services-items">
          <div className="services-item">
            <Link to="/Services/Human-Resource">
              <FaUsers />
              <h3>Human Resources</h3>
            </Link>
          </div>

          <div className="services-item">
            <Link to="/Services/Labour-Law-Compliance">
              <GoLaw />
              <h3>Labour Law Compliance & Litigations</h3>
            </Link>
          </div>

          <div className="services-item">
            <Link to="/Services/Investigation">
              <RiGovernmentFill />
              <h3>Investigation, Fraud & Governance</h3>
            </Link>
          </div>
        </div>
      </div>

      {/* <div className='parallax'>
        <div className='parallax-content'>

        <div className='parallax-content-right'>
        <h3>A New Edge to Human Resources, Compliance, Investigation, Fraud & Governanace</h3>
        <p>Customer Centricity, Innovative and agile Coeus Inc Services Pvt. Ltd. is a tech-enabled, seamless and evolving new edge organizations that specializes in Talent Acquisition, Compliance, Fraud & Investigation along with host of all other Human Resource services.</p>
        </div>
        </div>

      </div> */}
    </section>
  );
};

export default Our_Services;
