import React from 'react';
import './Features.css';
import { Tabs } from '../../components/export';
const Features = () => {
  return (
    <section className='features-section'>
        <div className='features'>
            {/* <h2>Features</h2> */}
            <div className='features-content'>
              <Tabs />
            </div>
        </div>
    </section>
  )
}

export default Features;