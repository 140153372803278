import React from 'react';
import { Link } from 'react-router-dom';
import './CTA.css';


const CTA = () => {
  return (
    <section className='cta-section'>
        <div className='cta gradient_bg2'>
            <h3> Get in touch with us</h3>
            <Link to='/Contact' ><p className='cta-btn'>Connect with Us</p></Link>
        </div>
    </section>
  )
}

export default CTA