import React from 'react'
import './About.css';


const About = () => {
  return (
    <section className='about-section' id='/About'>
      <div className='about'>
        <div className='parallax'>
          <div className='parallax-content'>
            <h2 className='sub-title'>About Coeus</h2>

            <div className='parallax-content-right'>
              <h3>A New Edge to Human Resources, Compliance, Investigation, Fraud & Governance</h3>
              <p>Customer Centricity, Innovative and agile Coeus Multi Services Pvt. Ltd. is a tech-enabled, seamless and evolving new edge organizations that specializes in Talent Acquisition, Compliance, Fraud & Investigation along with host of all other Human Resource services.</p>
            </div>
          </div>

        </div>
      </div>

    </section>

  )
}


export default About;