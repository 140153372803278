import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaWhatsappSquare } from "react-icons/fa";
// import { FaPhoneSquareAlt } from 'react-icons/fa';
import Coeus_Logo_Footer from "../../Assets/CoeusLogo_footer.png";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="footer-section">
      <div className="footer">
        <div className="footer-content">
          <div className="footer-items">
            <div className="footer_logo">
              <a href="/">
                <img loading="lazy" src={Coeus_Logo_Footer} alt="logo" />
              </a>
            </div>
          </div>

          <div className="footer-items">
            <h5>
              <FaMapMarkerAlt />
              <p>
                22nd Main, 23 cross, Sector 2, HSR Layout, Bangalore, 560102
              </p>
            </h5>

            <h5>
              <FaMapMarkerAlt />
              <p>Patan Devi Colony,Guljarbagh, Patna, 800007</p>
            </h5>
          </div>

          <div className="footer-items">
            <h5>
              <a href="mailto:info@coeus.org.in">
                <FaEnvelope />
                info@coeus.org.in
              </a>
            </h5>
            <h5>
              <a href="mailto:talent@coeus.org.in">
                <FaEnvelope />
                talent@coeus.org.in
              </a>
            </h5>
            <h5>
              <a href="mailto:compliance@coeus.org.in">
                <FaEnvelope />
                compliance@coeus.org.in
              </a>
            </h5>
            <h5>
              <a href="mailto:investigation@coeus.org.in">
                <FaEnvelope />
                investigation@coeus.org.in
              </a>
            </h5>
          </div>

          <div className="footer-items">
            <h5>
              <a
                href="https://api.whatsapp.com/send?phone=919102045447"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsappSquare />
                +91 9102045447
              </a>
            </h5>
            {/* <h5><a href="tel:+919108534223"><FaPhoneSquareAlt/>+91 9108534223</a></h5> */}
          </div>
        </div>

        <div className="copy-rights">
          {/* Use dynamic currentYear for the copyright */}
          <p>Coeus © {currentYear} All Rights reserved</p>
        </div>
        <div className="developer">
          <p>
            Made with ❤ by{" "}
            <a
              href="https://www.linkedin.com/in/athar-husain"
              target="_blank"
              rel="noreferrer"
            >
              Freelancer
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
