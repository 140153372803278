import {React, useEffect, useState }  from 'react';
// import { FaArrowUp } from 'react-icons/fa';
import './BackToTop.css';

const BackToTop = () => {
    const [ BackToTop, setBackToTop ] = useState(false);

    useEffect( () => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100 ) {
                setBackToTop(true)
            }else{
                setBackToTop(false)
            }
        })
    }, [])

    const scrollup = () => {
        window.scrollTo( {
            top:0,
            behavior:'smooth'
        })
    }
  return (
    <div className='backtotop'>
        {BackToTop && (<button onClick={scrollup} title='Scroll To Top'>^</button>)}
    </div>
    
  )
}

export default BackToTop