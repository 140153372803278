import React from 'react';
import './Feature.css';

const Feature = ({ imgUrl, sub_title, title, content }) => {
  return (
    
      <div className='feature-container'>
        <div className='feature-container-image'>
          <img src={imgUrl} alt={ title } />
        </div>
        <div className='feature-container-content'>
          <div className='feature-inner-content'>
            {/* <p className='sub-title'>{sub_title}</p> */}
            <h2>{title}</h2>
            <p>{content}</p>
          </div>
        </div>
      </div>
   
  )
}

export default Feature