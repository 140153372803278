import React, { useState } from 'react';
import { FaDiagnoses, FaHeadSideVirus, FaHandshake, FaUsersCog,FaBezierCurve,FaCogs, FaGg, FaAngleDoubleRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';
import './Tabs.css';

const Tabs = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className='tabs-container'>

      <div className='bloc-tabs'>
        <button
          className={toggleState === 1 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(1)}
        >
        <p><FaCogs /></p>
        Human Resource Services
        </button>

        <button
          className={toggleState === 2 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(2)}
        >
        <p><FaUsersCog /></p>
        	Establishment Compliance 
        </button>

        <button
          className={toggleState === 3 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(3)}
        >
          <p><FaHandshake /></p>
          Vendor Compliance Management
        </button>

        <button
          className={toggleState === 4 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(4)}
        >
          <p><FaDiagnoses /></p>
      	Compliance & Governance
        </button>
        
        <button
          className={toggleState === 5 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(5)}
        >
          <p><FaHeadSideVirus /></p>
          Compliance & Ethics
        </button>

        <button
          className={toggleState === 6 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(6)}
        >
          <p><FaGg /></p>
          Corporate
        </button>
       
        <button
          className={toggleState === 7 ? 'tabs active-tabs' : 'tabs'}
          onClick={() => toggleTab(7)}
        >
          <p><FaBezierCurve /></p>
          Litigation Support
        </button>
      </div>

      <div className='content-tabs'>
        
        <div className={toggleState === 1 ? "tabs-content active-content" : "tabs-content"}>
        <h2>Human Resource Services</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae aliquam a odio cupiditate maxime neque, quibusdam</p> */}
          <aside>
            <p><FaAngleDoubleRight />Staffing</p>
            <p><FaAngleDoubleRight />Hiring & RPO</p>
            <p><FaAngleDoubleRight />Infrastructure & Asset</p>
            <p><FaAngleDoubleRight />Od and L&D</p>
          </aside>
          
          
          <Link to='/Services/Human-Resource'><button className='tabs-btn'>Know more</button></Link>
        </div>

        <div className={toggleState === 2 ? "tabs-content active-content" : "tabs-content"}>
          <h2>Establishment Compliance</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
          <aside>
            <p><FaAngleDoubleRight />Registration / Licensing & Renewals</p>
            <p><FaAngleDoubleRight />Registers & Records</p>
            <p><FaAngleDoubleRight />Returns</p>
            <p><FaAngleDoubleRight />Inspection Managemnet</p>
            <p><FaAngleDoubleRight />Visits / Audits</p>
            <p><FaAngleDoubleRight />Compliance Training</p>
          </aside>
          
          <Link to='/Services/Labour-Law-Compliance'><button className='tabs-btn'>Know more</button></Link>


        </div>
        
        <div className={toggleState === 3 ? "tabs-content active-content" : "tabs-content"}>
          <h2>Vendor Compliance Management</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae aliquam a odio cupiditate maxime neque, </p> */}
          <aside>
            <p><FaAngleDoubleRight />Vendor Compliance Audit</p>
            <p><FaAngleDoubleRight />Inspection Management</p>
          </aside>
          
          <Link to='/Services/Labour-Law-Compliance'><button className='tabs-btn'>Know more</button></Link>

        </div>
        
        <div className={toggleState === 4 ? "tabs-content active-content" : "tabs-content"}>
          <h2>Compliance & Governance</h2>
          {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p> */}
          <aside>
            <p><FaAngleDoubleRight />Provident Fund</p>
            <p><FaAngleDoubleRight />Employee State Insurance Corportaion</p>
            <p><FaAngleDoubleRight />Labour Welfare Fund</p>
          </aside>
          <Link to='/Services/Human-Resource'><button className='tabs-btn'>Know more</button></Link>
        

        </div>
        
        <div className={toggleState === 5 ? "tabs-content active-content" : "tabs-content"}>
          <h2>Compliance & Ethics</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae aliquam a odio cupiditate maxime neque,</p> */}
          <aside>
            <p><FaAngleDoubleRight />Anti-Money Laundering</p>
            <p><FaAngleDoubleRight />Securities Violations Anti-Corruption</p>
            <p><FaAngleDoubleRight />Sanctions & Political Exposure</p>
            <p><FaAngleDoubleRight />Wokplace Violence & Harrasment</p>
            <p><FaAngleDoubleRight />Contact Tracing</p>
            <p><FaAngleDoubleRight />Whistleblower Claims</p>
            <p><FaAngleDoubleRight />Conflicts of interest</p>
            <p><FaAngleDoubleRight />Audits</p>
          </aside>
          
          <Link to='/Services/Investigation'><button className='tabs-btn'>Know more</button></Link>

        </div>
        
        <div className={toggleState === 6 ? "tabs-content active-content" : "tabs-content"}>
          <h2>Corporate</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae aliquam a odio cupiditate maxime neque, quibusdam, dolorem veniam debitis unde eum vitae.</p> */}
          <aside>
            <p><FaAngleDoubleRight />Internal & External Fraud</p>
            <p><FaAngleDoubleRight />Intellectual Property Protection</p>
            <p><FaAngleDoubleRight />Brand Protection</p>
            <p><FaAngleDoubleRight />Counterfeit</p>
            <p><FaAngleDoubleRight />Loss Prevention</p>
            <p><FaAngleDoubleRight />Infringement of Patents, Trademarks & Copyright</p>
            <p><FaAngleDoubleRight />Trade Secrets Violations</p>
            <p><FaAngleDoubleRight />Supply Chain Investigations</p>
            <p><FaAngleDoubleRight />Specific Threats POI / GOI</p>
            <p><FaAngleDoubleRight />Background Vetting</p>
            <p><FaAngleDoubleRight />Online Vulnerability Assessments</p>
          </aside>
          
          <Link to='/Services/Investigation'><button className='tabs-btn'>Know more</button></Link>




        </div>
        
        <div className={toggleState === 7 ? "tabs-content active-content" : "tabs-content"}>
          <h2>Litigation Support</h2>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae aliquam a odio cupiditate maxime neque, quibusdam, dolorem veniam debitis unde eum vitae.</p> */}
          <aside>
            <p><FaAngleDoubleRight />Asset Searches</p>
            <p><FaAngleDoubleRight />Computer forensics</p>
            <p><FaAngleDoubleRight />E-Discovery</p>
            <p><FaAngleDoubleRight />Forensic Accounting</p>
            <p><FaAngleDoubleRight />Evidence Management</p>
            <p><FaAngleDoubleRight />Pre-trial Due Diligence</p>
            <p><FaAngleDoubleRight />Local Expert Witness</p>
            <p><FaAngleDoubleRight />Due Diligence</p>
          </aside>
          
          <Link to='/Services/Investigation'><button className='tabs-btn'>Know more</button></Link>

        </div>
      </div>
    </div>

  );
};

export default Tabs;