// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// // import { BrowserRouter } from 'react-router-dom';
// // import { Myroutes } from './components/export';
// import { Nav, Footer } from './Container/export'
// import { Home } from './Pages/export';
// import { BackToTop } from './components/export'
// import { Services, About, Human_Resource, Compliance, Investigation, Contact, Nopage } from './Pages/export';
// import './App.css';

// function App() {
//   return (
//     <div className="App app-container">

//       <Nav />

//       <BackToTop />

//       <div className='content'>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/About" element={<About />} />
//           {/* /* ------------Services- Routing---------  */}
//           <Route path="/Services" element={<Services />} />
//           <Route path="/Services/Human-Resource" element={<Human_Resource />} />
//           <Route path="/Services/Investigation" element={< Investigation />} />

//           <Route path="/Services/Labour-Law-Compliance" element={< Compliance />} />
//           {/* /* ------------Services- Routing End ---------  */}

//           <Route path="/Contact" element={<Contact />} />

//          {/* ========== Nopage =========== */}
//           <Route path="/*" element={<Nopage />} />

//         </Routes>
//         <Footer />
//       </div>

//     </div>
//   );
// }

// export default App;

import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Nav, Footer } from "./Container/export";
import { BackToTop } from "./components/export";
import "./App.css";
import Loader from "./Loader";

// Lazy load your components
const Home = lazy(() => import("./Pages/Home/Home"));
const About = lazy(() => import("./Pages/About/About"));
const Services = lazy(() => import("./Pages/Services/Services"));
const Human_Resource = lazy(() =>
  import("./Pages/Services/Human_Resource/Human_Resource")
);
const Investigation = lazy(() =>
  import("./Pages/Services/Investigation/Investigation")
);
const Compliance = lazy(() => import("./Pages/Services/Compliance/Compliance"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Nopage = lazy(() => import("./Pages/Nopage/Nopage"));

function App() {
  return (
    <div className="App app-container">
      <Nav />
      <BackToTop />
      <div className="content">
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            {/* ------------Services- Routing---------  */}
            <Route path="/Services" element={<Services />} />
            <Route
              path="/Services/Human-Resource"
              element={<Human_Resource />}
            />
            <Route path="/Services/Investigation" element={<Investigation />} />
            <Route
              path="/Services/Labour-Law-Compliance"
              element={<Compliance />}
            />
            {/* ------------Services- Routing End ---------  */}
            <Route path="/Contact" element={<Contact />} />

            {/* ========== Nopage =========== */}
            <Route path="/*" element={<Nopage />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </div>
  );
}

export default App;
